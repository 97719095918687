/* This fires as soon as the element enters the DOM */
.menu-backdrop-enter{
    z-index:1000 !important;
    background:transparent;
    transition: 500ms;
}
/* This is where we can add the transition*/
.menu-backdrop-enter-active{
    backdrop-filter: blur(4px);
    background:rgba(46,65,95,.75);
    transition: 500ms;
}

.menu-backdrop-enter-done,.menu-backdrop-exit{
    /* background:orange; */
    z-index:1000 !important;
    backdrop-filter: blur(4px);
    background:rgba(46,65,95,.75);
    transition: 500ms;
}

.menu-backdrop-enter-active.dark,.menu-backdrop-enter-done.dark,.menu-backdrop-exit.dark{
    background:rgba(14,14,17,.85);
}

/* fires as element leaves the DOM*/
.menu-backdrop-exit-active,.menu-backdrop-exit-active.dark{
    background:transparent;
    backdrop-filter: none;
    transition: 500ms;
}
.menu-backdrop-exit-done{
    z-index:-1;
    
}

/**Sidepanel**/
.sp-animate-panel-enter .sp-wrapper{
    z-index:1055 !important;
    background:transparent;
    transition: 500ms;
}
/* This is where we can add the transition*/
.sp-animate-panel-enter-active .sp-wrapper{
    backdrop-filter: blur(4px);
    background:rgba(46,65,95,.75);
    transition: 500ms;
}

.sp-animate-panel-enter-done .sp-wrapper,.sp-animate-panel-exit .sp-wrapper{
    /* background:orange; */
    z-index:1055 !important;
    backdrop-filter: blur(4px);
    background:rgba(46,65,95,.75);
    transition: 500ms;
}

.sp-animate-panel-enter-active.dark .sp-wrapper,.sp-animate-panel-enter-done.dark .sp-wrapper,.sp-animate-panel-exit.dark .sp-wrapper{
    background:rgba(14,14,17,.85);
}

/* fires as element leaves the DOM*/
.sp-animate-panel-exit-active .sp-wrapper,.sp-animate-panel-exit-active.dark .sp-wrapper{
    background:transparent;
    backdrop-filter: none;
    transition: 500ms;
}
.sp-animate-panel-exit-done{
    z-index:-1;
    
}

/* .sp-animate-panel-enter .sp-wrapper-content{} */
.sp-animate-panel-enter .sp-wrapper-content{
    right:-100% !important;
}

.sp-animate-panel-appear-active .sp-wrapper-content{
    right:-100% !important;
}

.sp-animate-panel-enter-done .sp-wrapper-content,.sp-animate-panel-exit .sp-wrapper-content,.sp-animate-panel-enter-active .sp-wrapper-content{
    right:0 !important;
    transition: 500ms;
}

.sp-animate-panel-exit-active .sp-wrapper-content{
    transition: 500ms;
    right:-100% !important;
}
.sp-animate-panel-exit-done .sp-wrapper-content{
    right:-100% !important;
}

@media screen and (max-width: 63.9375em) {
    .sp-animate-panel-enter .sp-wrapper-content{
        right:-0 !important;
        top:unset !important;
        bottom:-100% !important; 
    }
    
    .sp-animate-panel-appear-active .sp-wrapper-content{
        right:-0 !important;
        top:unset !important;
        bottom:100% !important; 
    }
    
    .sp-animate-panel-enter-done .sp-wrapper-content,.sp-animate-panel-exit .sp-wrapper-content,.sp-animate-panel-enter-active .sp-wrapper-content{
        right:0 !important;
        transition: 500ms;
        top:unset !important;
        bottom:0 !important; 
    }
    
    .sp-animate-panel-exit-active .sp-wrapper-content{
        transition: 500ms;
        right:-0 !important;
        top:unset !important;
        bottom:-100% !important; 
    }
    .sp-animate-panel-exit-done .sp-wrapper-content{
        right:-0 !important;
        top:unset !important;
        bottom:-100% !important; 
    }
}


/** sidepanel body view
**/

/* This fires as soon as the element enters the DOM */
.sp-animate-bodyview-enter{
    z-index:1058 !important;
    background:transparent;
    right:0 !important;
}
/* This is where we can add the transition*/
.sp-animate-bodyview-enter-active{
    backdrop-filter: blur(4px);
    background:rgba(46,65,95,.75) !important;
    transition: 500ms;
    transition-delay: 20ms;
    right:0 !important;
}
.sp-animate-bodyview-enter-active.dark{
    background:rgba(14,14,17,.85) !important;
}

.sp-animate-bodyview-enter-done,.sp-animate-bodyview-exit{
    z-index:1058 !important;
    backdrop-filter: blur(4px);
    background:rgba(46,65,95,.75) !important;
    transition: 500ms;
    right:0 !important;
}
.sp-animate-bodyview-enter-done.dark,.sp-animate-bodyview-exit.dark{
     background:rgba(14,14,17,.85) !important;
     backdrop-filter: blur(4px);
}

/* fires as element leaves the DOM*/
.sp-animate-bodyview-exit-active,.sp-animate-bodyview-exit-active.dark{
    background:transparent !important;
    backdrop-filter: none;
    transition: 300ms;

    right:0 !important;
}
.sp-animate-bodyview-exit-done{
    z-index:-1;
    right:-100% !important;
}

.sp-animate-bodyview-enter .sp-bodyview-content-box{
    right:-100% !important;
    z-index:1090 !important;
}

.sp-animate-bodyview-appear-active .sp-bodyview-content-box{
    right:-100% !important;
    z-index:1090 !important;
}

.sp-animate-bodyview-enter-done .sp-bodyview-content-box,.sp-animate-bodyview-exit .sp-bodyview-content-box,.sp-animate-bodyview-enter-active .sp-bodyview-content-box{

    right:0 !important;
    z-index:1090 !important;
    transition: 500ms;
}

.sp-animate-bodyview-exit-active .sp-bodyview-content-box{
    transition: 500ms;
    right:-100% !important;
    z-index:1090 !important;
}
.sp-animate-bodyview-exit-done .sp-bodyview-content-box{
    right:-100% !important;
    z-index:1052 !important;
}

@keyframes pulsate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes pulse {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
  }
